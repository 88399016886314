import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

export function initPartnerSlider() {
    const partnerSliders = document.querySelectorAll(".dm-partners-slider");

    if (partnerSliders) {
        const observerOptions = {
            threshold: [0.25,0.5,0.75,1]
        };

        for (const partnerSlider of partnerSliders) {
            const partnerSliderEl = new Splide(partnerSlider, {
                type: "loop",
                arrows: false,
                pagination: false,
                perPage: 2,
                gap: "3.125rem",
                mediaQuery: "min",
                flickPower: 100,
                autoScroll: {
                    autoStart: false,
                    speed: 1
                },
                breakpoints: {
                    640: {
                        perPage: 4
                    },
                    768: {
                        perPage: 6
                    },
                    992: {
                        perPage: 8
                    }
                }
            });

            partnerSliderEl.mount({ AutoScroll });

            // Intersection observer to start autoplay only when slider is in viewport
            const partnerObserver = new IntersectionObserver(entries => {
                if (entries[0].intersectionRatio >= 0.5) {
                    partnerSliderEl.Components.AutoScroll?.play();
                } else {
                    partnerSliderEl.Components.AutoScroll?.pause();
                }
            }, observerOptions);

            partnerObserver.observe(partnerSlider);
        }
    }
}