import Splide from "@splidejs/splide";

export function initInspirationSlider() {
    const inspirationSliders = document.querySelectorAll(".dm-inspiration");

    if (inspirationSliders) {
        for(const inspirationSlider of inspirationSliders) {
            const imageSliderEl = inspirationSlider.querySelector(".dm-inspiration__images");
            const textSliderEl = inspirationSlider.querySelector(".dm-inspiration__text");

            const imageSlider = new Splide(imageSliderEl, {
                arrows: false,
                pagination: false,
                type: "loop",
                gap: "0.625rem",
                flickPower: 100,
                mediaQuery: "min",
                padding: {
                    right: "1.625rem"
                },
                breakpoints: {
                    768: {
                        gap: "1.5rem",
                        padding: {
                            right: "10rem"
                        },
                    },
                    992: {
                        gap: "1.5rem",
                        padding: {
                            right: "3rem"
                        },
                        pagination: true
                    },
                    1400: {
                        padding: {
                            right: "7.5rem"
                        },
                    },
                    1600: {
                        padding: {
                            right: "30%"
                        }
                    }
                }
            });

            const textSlider = new Splide(textSliderEl, {
                type: "fade",
                rewind: true,
                arrows: false,
                flickPower: 100,
                mediaQuery: "min",
                breakpoints: {
                    992: {
                        pagination: false
                    }
                }
            });

            imageSlider.sync(textSlider);

            imageSlider.mount();
            textSlider.mount();

            const prevArrows = inspirationSlider.querySelectorAll(".splide__arrow--prev");
            const nextArrows = inspirationSlider.querySelectorAll(".splide__arrow--next");

            for(const prevArrow of prevArrows) {
                prevArrow.addEventListener("click", () => {
                    textSlider.go('-${i}');
                });
            }

            for(const nextArrow of nextArrows) {
                nextArrow.addEventListener("click", () => {
                    textSlider.go('+${i}');
                });
            }
        }
    }
}