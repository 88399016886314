import Splide from "@splidejs/splide";

export function initClientQuotesSlider() {
    const clientQuotesSliders = document.querySelectorAll(".dm-client-quotes__slider");
   
    if (clientQuotesSliders.length > 0) {
        for (const clientQuotesSlider of clientQuotesSliders) {
            const numberOfSlides = clientQuotesSlider.querySelectorAll(".splide__slide").length;
        
            new Splide(clientQuotesSlider, {
                type: "loop",
                gap: "1.75rem",
                arrows: false,
                pagination: true,
                mediaQuery: "min",
                padding: "15%",
                updateOnMove: true,
                flickPower: 100,
                perMove: 1,
                clones: numberOfSlides,
                breakpoints: {
                    "768": {
                        gap: 0,
                        padding: "20%",
                    },
                    "1200": {
                        arrows: true
                    },
                    "1400": {
                        padding: "26.55%",
                    }
                }
            }).mount();
        }
    }
}