import Splide from "@splidejs/splide";

export function initImageSlider() {
    const imageSliders = document.querySelectorAll(".dm-image-slider__slider");

    if (imageSliders) {
        for (const imageSlide of imageSliders) {
            const slider = new Splide(imageSlide, {
                arrows: false,
                perMove: 1,
                type: "loop",
                gap: "0.625rem",
                flickPower: 100,
                mediaQuery: "min",
                padding: "5%",
                updateOnMove: true,
                breakpoints: {
                    "768": {
                        gap: "1.5rem",
                        padding: "10.75%",
                    },
                    "992": {
                        padding: "20%",
                    },
                    "1200": {
                        padding: "26%",
                    },
                    "1600": {
                        padding: "30%"
                    }
                }
            });

            slider.on("move", (newIndex, prevIndex, destIndex) => {
                const video = slider.Components.Slides.getAt(prevIndex).slide.querySelector("video");

                if (video) {
                    video.pause();
                }
            });

            slider.on("moved", (newIndex, prevIndex, destIndex) => {
                const video = slider.Components.Slides.getAt(newIndex).slide.querySelector("video");

                if (video) {
                    video.play();
                }
            });

            slider.mount();
        }
    }
}