export function initAccordion() {
    const accordionToggle = document.querySelectorAll("[data-toggle-accordion]");

    if (accordionToggle) {
        accordionToggle.forEach(accordion => {
            if (accordion.classList.contains("initialised")) return;

            accordion.classList.add("initialised");

            accordion.addEventListener("click", () => {
                const accordionElement = accordion.closest(".dm-accordion");
                const multipleActiveItems = accordionElement.dataset.multipleActiveItems;
                const isExpanded = accordion.getAttribute("aria-expanded");
                const accordionBody = accordion.parentElement.nextElementSibling;

                if (multipleActiveItems === "true") {
                    toggleSingleAccordionItem(accordion, accordionBody, isExpanded);
                } else {
                    toggleAllAccordionItems(accordionElement, accordion, accordionBody, isExpanded);
                }
            });
        });

        function toggleSingleAccordionItem(trigger, accBody, isExpanded) {
            if (isExpanded === "true") {
                trigger.setAttribute("aria-expanded", "false");
                accBody.setAttribute("aria-hidden", "true");

            } else {
                trigger.setAttribute("aria-expanded", "true");
                accBody.setAttribute("aria-hidden", "false");
            }
        }

        function toggleAllAccordionItems(accordionElement, trigger, accBody, isExpanded) {
            const accordionItems = accordionElement.querySelectorAll(".dm-accordion__item");

            // collapse all accordion items
            accordionItems.forEach(item => {
                const button = item.querySelector("[data-toggle-accordion]");
                const body = item.querySelector(".dm-accordion__body");

                button.setAttribute("aria-expanded", "false");
                body.setAttribute("aria-hidden", "true");
            });

            // open clicked accordion item
            toggleSingleAccordionItem(trigger, accBody, isExpanded);
        }
    }
}

