import Splide from "@splidejs/splide";

export function initTeaserCardsSlider() {
    const teaserCardSliders = document.querySelectorAll(".dm-teaser-card-slider__slider");

    if(teaserCardSliders) {
        for (const slider of teaserCardSliders) {
            const inEditMode = slider.getAttribute("data-in-edit-mode");

            new Splide(slider, {
                arrows: false,
                type: inEditMode === "true" ? "slide" : "loop",
                padding: "10%",
                mediaQuery: "min",
                flickPower: 100,
                gap: "1.625rem",
                perMove: 1,
                breakpoints: {
                    "768": {
                        padding: 0,
                        perPage: 2
                    },
                    "1180": {
                        perPage: 3,
                    },
                    "1400": {
                        arrows: true
                    }
                }
            }).mount();
        }
    }
}