export function handleVideoModule() {
    const videoModules = document.querySelectorAll(".dm-video-module");

    if(videoModules.length > 0) {
        for(const videoModule of videoModules) {
            const video = videoModule.querySelector("video");
            const playBtn = videoModule.querySelector("[data-play-video]");

            playBtn.addEventListener("click", () => {
                playVideo(video, playBtn);
            });

            video.addEventListener("click", () => {
                pauseVideo(video, playBtn);
            });

            video.addEventListener("ended", () => {
                resetVideo(video, playBtn);
            });
        }

        function playVideo(video, playBtn) {
            playBtn.classList.add("playing");
            video.play();
            video.setAttribute("controls", "true");
        }

        function pauseVideo(video, playBtn) {
            playBtn.classList.remove("playing");
            video.pause();
            video.removeAttribute("controls");
        }

        function resetVideo(video, playBtn) {
            playBtn.classList.remove("playing");
            // video.currentTime = 0;
            video.removeAttribute("controls");

            video.load();
        }
    }
}