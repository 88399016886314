import Splide from "@splidejs/splide";

export function initBlogArticleSlider() {
    const blogArticleCardSliders = document.querySelectorAll(".dm-blog-articles__slider");
    
    if(blogArticleCardSliders) {
        let splide;

        for (const slider of blogArticleCardSliders) {
            const inEditMode = slider.getAttribute("data-in-edit-mode");

            splide = new Splide(slider, {
                arrows: false,
                pagination: true,
                type: inEditMode === "true" ? "slide" : "loop",
                gap: "0.625rem",
                flickPower: 100,
                mediaQuery: "min",
                breakpoints: {
                    768: {
                        gap: "1.5rem",
                        perPage: 2,
                    },
                    1200: {
                        perPage: 3
                    },
                    1400: {
                        arrows: true
                    }
                }
            }).mount();
        }
        const prevBtn = document.getElementById('prev');
        const nextBtn = document.getElementById('next');

        if(prevBtn) {
            prevBtn.addEventListener('click', function () {
                splide.go(`${splide.index} - 1`);
            });
        }

        if(nextBtn) {
            nextBtn.addEventListener('click', function () {
                splide.go(`${splide.index} + 1`);
            });
        }
    }
}